<template>
    <div class="upsDowns">
        <div class="ups">
            <div class="upsDownsEl" v-for="up in ups">
                <i class="fas fa-square-plus"/>{{ up }}
            </div>
        </div>
        <div class="downs">
            <div class="upsDownsEl" v-for="down in downs">
                <i class="fas fa-square-minus"/>{{ down }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UpsDowns',
        props: ['ups', 'downs']
    }
</script>

<style scoped lang="scss">
    @import "../css/colors";

    .upsDowns {
        margin: 8px 0;
        display: flex;

        .ups, .downs {
            flex: 0 0 50%;
            padding: 0;
            color: #767676;

            .upsDownsEl {
                display: flex;
                align-items: center;
                padding: 0;
                margin: 12px 8px;
                hyphens: auto;
                line-height: 1.4;
            }

            i {
                font-size: 16pt;
                margin-right: 8px;
            }
        }

        .ups i {
            color: $green;
        }

        .downs i {
            color: $red;
        }
    }
</style>
