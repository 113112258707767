<template>
    <div>
        <button @click="$router.push('/')">zurück</button>
        <div v-if="content">
            <h2 class="center">{{ content.name }}</h2>
            <img class="contentImg" :src="'/content_images/' + content.image" :alt="content.image">
            <a v-if="content.image_src" class="image_src" :href="content.image_src" target="_blank">Link zur Bildquelle</a>
            <p v-html="content.text"></p>
            <UpsDowns :ups="content.ups" :downs="content.downs"/>
        </div>
    </div>
</template>

<script>
    import UpsDowns from "@/components/UpsDowns";
    import {mapState} from "vuex";

    export default {
        name: 'ContentDurchlauferhitzer',
        components: {UpsDowns},
        computed: {
            ...mapState(["contents"])
        },
        data() {
            return {
                id: this.$route.params.id,
                content: null
            }
        },
        mounted() {
            this.content = this.contents.find(e => e.id === this.id);
        }
    }
</script>

<style scoped lang="scss">
    @import "@/css/colors.scss";

    .contentImg {
        margin: 8px auto;
        display: block;
        max-width: 220px;
        max-height: 220px;
        width: auto;
        height: auto;
    }

    .image_src {
        display: block;
        text-align: center;
        color: $ifem_text_grey;
        font-size: 11px;
        text-decoration: none;
    }
</style>
